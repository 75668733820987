export function getEnvVar(name) {
  const storedEnv = localStorage.getItem('env');

  if (storedEnv) {
    const parsedEnv = JSON.parse(storedEnv);
    const env_var = parsedEnv[name];

    if (env_var) {
      return `${env_var}`;
    }
  }

  const xhr = new XMLHttpRequest();
  xhr.open('GET', '/public_env', false);
  xhr.send();

  if (xhr.status === 200) {
    const env = JSON.parse(xhr.responseText);
    localStorage.setItem('env', JSON.stringify(env));

    const env_var = env[name];

    if (env_var) {
      return `${env_var}`;
    } else {
      throw new Error(
        'Desired value not found in the fetched environment data.',
      );
    }
  } else {
    console.error('Error fetching the file:', xhr.statusText);
  }
  return '';
}
