import { Checkbox, Col, Row, Spin } from 'antd';
import React from 'react';
import FacilitySelectRow from './FacilitySelectRow';

function FacilityContent({ facilities, facility, onChangeFacility }) {
  if (!facilities || facilities.length === 0) {
    return (
      <div className="check-loader-spin-wrapper">
        <Spin />
      </div>
    );
  }

  return (
    <Checkbox.Group
      value={facility}
      className="facility-dropdown"
      onChange={onChangeFacility}>
      <Row>
        <Col span={12}>
          {facilities
            .slice(0, Math.ceil(facilities.length / 2))
            .map((facilityItem) => (
              <FacilitySelectRow
                key={facilityItem.facilityAndEquipmentId}
                facility={facility}
                facilityItem={facilityItem}
              />
            ))}
        </Col>
        <Col span={12}>
          {facilities
            .slice(Math.ceil(facilities.length / 2))
            .map((facilityItem) => (
              <FacilitySelectRow
                key={facilityItem.facilityAndEquipmentId}
                facility={facility}
                facilityItem={facilityItem}
              />
            ))}
        </Col>
      </Row>
    </Checkbox.Group>
  );
}

export default FacilityContent;
