import { Checkbox, Row, Spin } from 'antd';
import TherapySelectRow from './TherapySelectRow';
import React from 'react';

const CheckboxGroup = Checkbox.Group;

function TherapyContent({ therapies, onChangeAreas, areas }) {
  if (!therapies || therapies.length === 0) {
    return (
      <div className="check-loader-spin-wrapper">
        <Spin />
      </div>
    );
  }

  return (
    <CheckboxGroup
      value={areas}
      className="therapeutic-dropdown"
      onChange={onChangeAreas}>
      <Row>
        {therapies.map((therapy) => (
          <TherapySelectRow
            key={therapy.therapeuticId}
            therapy={therapy}
            areas={areas}
          />
        ))}
      </Row>
    </CheckboxGroup>
  );
}

export default TherapyContent;
