import { Checkbox, Row } from 'antd';
import React from 'react';

function FacilitySelectRow({ facilityItem, facility }) {
  return (
    <Row span={24}>
      <Checkbox
        value={facilityItem.facilityAndEquipmentName}
        key={facilityItem.facilityAndEquipmentId}
        className={'check-options-facility'}
        disabled={
          facility.length <= 4
            ? false
            : !facility.includes(facilityItem.facilityAndEquipmentName)
        }>
        {facilityItem.facilityAndEquipmentName}
      </Checkbox>
    </Row>
  );
}

export default FacilitySelectRow;
