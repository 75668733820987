import { Col, Skeleton, Row, Result, FloatButton } from 'antd';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { faMegaphone } from '@fortawesome/pro-solid-svg-icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import SiteCard from '../../components/SiteCard';
import SearchBar from '../../components/SearchBar/SearchBar';
import util from '../../../utils/util';
import CommonService from '../../services/commonService';
import DummySiteCard from '../../components/DummySiteCard';

/**
 * The Search component displays a search bar and a list of sites based on the user's search criteria.
 * @returns A JSX element that displays the search bar and a list of sites.
 */
const Search = () => {
  const [siteCount, setSiteCount] = useState(0);
  const user = useSelector((state) => state.user);
  const allAreas = useSelector((state) => state.therapeuticArea);
  const allFacility = useSelector((state) => state.facility);
  const [sites, setSites] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [popularLoading, setPopularLoading] = useState(false);
  const [popularSearches, setPopularSearches] = useState([]);
  const [areas, setAreas] = React.useState([]);
  const [facility, setFacility] = React.useState([]);
  const promoData = useSelector((state) => state.promotionalBanner);

  // Fetches more search results from the server and updates the state with the new data.
  const fetchMoreResult = () => {
    let payload = util.getSearchPayload(
      allAreas,
      allFacility,
      selectedAreas,
      selectedFacility,
    );
    CommonService.searchSites(10, pageNumber + 1, payload).then((res) => {
      setSites([...sites, ...res.data.data]);
      setSiteCount(res.data.size);
    });
    setPageNumber(pageNumber + 1);
  };

  // Executes a search for sites based on the selected areas and facilities.
  const onSearch = () => {
    setSearchLoading(true);
    setPageNumber(0);
    let payload = util.getSearchPayload(
      allAreas,
      allFacility,
      selectedAreas,
      selectedFacility,
    );
    CommonService.searchSites(10, 0, payload)
      .then((res) => {
        setSites(res.data.data);
        setSiteCount(res.data.size);
        setSearchLoading(false);
      })
      .catch(() => {
        setSearchLoading(false);
      });
  };
  /**
   * Updates the selected areas and facilities, sets the search loading state to true,
   * sets the page number to 0, and makes a call to the CommonService to search for sites
   * based on the selected areas and facilities.
   * @param {{any}} item - the item to search for
   */
  const discoverSearch = (item) => {
    setSelectedAreas([item]);
    setAreas([item]);
    setSelectedFacility([]);
    setFacility([]);
    setSearchLoading(true);
    setPageNumber(0);
    let payload = util.getSearchPayload(
      allAreas,
      allFacility,
      [item],
      selectedFacility,
    );
    CommonService.searchSites(10, 0, payload)
      .then((res) => {
        setSites(res.data.data);
        setSiteCount(res.data.size);
        setSearchLoading(false);
      })
      .catch(() => {
        setSearchLoading(false);
      });
    window.scrollTo(0, 0);
  };

  /**
   * This useEffect hook is responsible for fetching popular searches and search results
   * based on the selected areas and facilities. It also sets the loading state for both
   * popular searches and search results.
   */
  useEffect(() => {
    window.scrollTo(0, 0);
    setSearchLoading(true);
    setPopularLoading(true);
    CommonService.getPopularSearches()
      .then((res) => {
        setPopularSearches(res.data.data);
        setPopularLoading(false);
      })
      .catch(() => {
        setPopularLoading(false);
      });
    let tempArea = [];
    let tempFacility = [];
    if (window.sessionStorage.getItem('facility')) {
      tempFacility = window.sessionStorage.getItem('facility').split(',');
      setSelectedFacility(window.sessionStorage.getItem('facility').split(','));
    }
    if (window.sessionStorage.getItem('areas')) {
      tempArea = window.sessionStorage.getItem('areas').split(',');
      setSelectedAreas(window.sessionStorage.getItem('areas').split(','));
    }
    let payload = util.getSearchPayload(
      allAreas,
      allFacility,
      tempArea,
      tempFacility,
    );
    CommonService.searchSites(10, pageNumber, payload)
      .then((res) => {
        setSites(res.data.data);
        setSiteCount(res.data.size);
        setSearchLoading(false);
      })
      .catch((err) => {
        setSearchLoading(false);
      });
  }, []);
  return (
    <>
      <div className="search-section">
        <SearchBar
          setSelectedFacility={setSelectedFacility}
          setSelectedAreas={setSelectedAreas}
          onSearch={onSearch}
          areas={areas}
          setAreas={setAreas}
          facility={facility}
          setFacility={setFacility}
        />
      </div>
      <div className="body-container">
        <FloatButton.BackTop className="back-to-top" />
        <div className="sites-header">
          Sites
          {user.length !== 0 ? (
            <p>
              {siteCount} {siteCount > 1 ? 'results' : 'result'}
            </p>
          ) : null}
        </div>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} md={24} lg={18} xl={18}>
            {searchLoading ? (
              <Row>
                <Skeleton active />
              </Row>
            ) : sites.length === 0 ? (
              <Result
                status="warning"
                title="No match found. Please update your search."
              />
            ) : (
              <InfiniteScroll
                dataLength={sites.length}
                next={fetchMoreResult}
                hasMore={sites.length !== siteCount}
                loader={<Skeleton active />}>
                {sites.map((site) => {
                  return (
                    <Row key={site.siteId}>
                      <SiteCard
                        siteData={site}
                        selectedAreas={selectedAreas}
                        areas={allAreas}
                      />
                    </Row>
                  );
                })}
              </InfiniteScroll>
            )}
            {user.length === 0 && sites.length !== 0 ? (
              <Row key={'dummySite'}>
                <DummySiteCard />
              </Row>
            ) : null}
          </Col>
          <Col xs={24} sm={24} md={24} lg={6} xl={6}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={24} xl={24}>
                {promoData && (
                  <Row>
                    <div className="search-signup-card">
                      <div className="search-signup-card-icon">
                        <FontAwesomeIcon icon={faMegaphone} />
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: promoData.value }}
                      />
                    </div>
                  </Row>
                )}
              </Col>
              <Col xs={24} sm={24} md={12} lg={24} xl={24}>
                <Row>
                  <div className="search-popular-card">
                    {!popularLoading ? (
                      popularSearches.length !== 0 ? (
                        <>
                          <div className="search-popular-card-header">
                            Discover other sites
                          </div>
                          <div className="discover-button-wrapper">
                            {popularSearches.length > 10
                              ? popularSearches.slice(0, 10).map((area) => {
                                  return (
                                    <div
                                      className="discover-button"
                                      key={area.therapeuticId}
                                      onClick={() => {
                                        discoverSearch(area.therapeuticName);
                                      }}>
                                      {area.therapeuticName}
                                    </div>
                                  );
                                })
                              : popularSearches.map((area) => {
                                  return (
                                    <div
                                      className="discover-button"
                                      key={area.therapeuticId}
                                      onClick={() => {
                                        discoverSearch(area.therapeuticName);
                                      }}>
                                      {area.therapeuticName}
                                    </div>
                                  );
                                })}
                          </div>
                        </>
                      ) : (
                        <Result status="warning" title="No Data to Display" />
                      )
                    ) : (
                      <Skeleton active />
                    )}
                  </div>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Search;
