import { Row, Col, Button } from 'antd';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThumbTack,
  faUserDoctor,
  faUsers,
} from '@fortawesome/pro-solid-svg-icons';
import { faBuilding } from '@fortawesome/sharp-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import SearchBar from '../../components/SearchBar/SearchBar';
import PromotionalImg from '../../../assets/images/promotional_img.png';
import HomeSignup from '../../../assets/images/home-signup-image.png';

/**
 * The Home component is the main landing page of the CRIO website. It displays a hero section with a tagline and a call to action button, followed by a search bar for finding clinical research sites. The component also includes promotional content and informational cards about CRIO's services. At the bottom of the page, there is a section for scheduling a demo with CRIO Connect.
 * @returns A React component that renders the Home page.
 */
const Home = () => {
  const history = useHistory();
  const [, setSelectedAreas] = useState([]);
  const [, setSelectedFacility] = useState([]);
  const [areas, setAreas] = useState([]);
  const [facility, setFacility] = useState([]);

  // Redirects the user to the search page.
  const onSearch = () => {
    history.push('/search');
  };

  return (
    <>
      <div className="main">
        <div className="hero">
          <div className="hero-text">
            <p>
              Make Site Selection Easier
              <br />
              <br />
              <strong>Browse our directory of tech-savvy sites</strong>
            </p>
          </div>
        </div>
        <div className="body-content">
          <SearchBar
            setSelectedFacility={setSelectedFacility}
            setSelectedAreas={setSelectedAreas}
            onSearch={onSearch}
            areas={areas}
            setAreas={setAreas}
            facility={facility}
            setFacility={setFacility}
          />
          <div className="body-text">
            CRIO is making site selection easier. CRIO Connect introduces
            sponsors and CROs to the best tech-enabled clinical research sites
            for studies.
          </div>
          <Row gutter={[24, 24]} className="promotion-row">
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              className="promotion-col">
              <div className="promotion-img">
                <img src={PromotionalImg} alt="promotional-img" />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              className="promotion-col">
              <div className="promotion-text">
                <h2>For Sponsors and CROs</h2>
                <p>
                  Browse our extensive collection of sites by site, principal
                  investigator, therapeutic areas, facility and equipment, and
                  more. and select the best ones for your upcoming studies!
                </p>
                <p>
                  <span>We’ve thought of everything.</span>
                </p>
              </div>
            </Col>
          </Row>
          <div className="promotional-cards-wrapper">
            <div className="promotional-cards-header">
              <h1>
                Join the community. Start a conversation. Explore CRIO’s
                tech-enabled sites
              </h1>
            </div>
            <div className="promotional-cards-container">
              <Col gutter={[24, 24]} className="promotional-cards-inner">
                <Row
                  gutter={[24, 24]}
                  style={{ columnGap: '6%', width: '100%' }}>
                  <Col
                    xl={7}
                    lg={7}
                    md={24}
                    sm={24}
                    xs={24}
                    className="promotional-card">
                    <h3>Streamline Site Selection</h3>
                    <p>
                      Search, filter and compare qualified sites based on
                      equipment/facility, therapeutic areas, etc
                    </p>
                  </Col>
                  <Col
                    xl={7}
                    lg={7}
                    md={24}
                    sm={24}
                    xs={24}
                    className="promotional-card">
                    <h3>Improve Quality</h3>
                    <p>CRIO-enabled sites are verified, superior performers</p>
                  </Col>
                  <Col
                    xl={7}
                    lg={7}
                    md={24}
                    sm={24}
                    xs={24}
                    className="promotional-card">
                    <h3>Increase success</h3>
                    <p>
                      Choose CRIO-enabled sites to increase the chances of
                      successful outcomes, faster approval times and higher
                      revenue
                    </p>
                  </Col>
                </Row>
              </Col>
            </div>
          </div>
          <div className="promotion-row-card">
            <Row gutter={[24, 24]}>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                className="promotion-col">
                <div className="promotion-card-left">
                  <h2>CRIO Connect profiles detail key site statistics</h2>
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                className="promotion-col">
                <div className="promotion-card-right">
                  <div className="promotion-card-right-row">
                    <div>
                      <FontAwesomeIcon icon={faUsers} />
                      <p># of subjects enrolled for each study</p>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faBuilding} />
                      <p>Facility Capabilities</p>
                    </div>
                  </div>
                  <div className="promotion-card-right-row">
                    <div>
                      <FontAwesomeIcon icon={faUserDoctor} />
                      <p>PI Name and experience</p>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faThumbTack} />
                      <p># of sites and location</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="home-signup">
            <Row gutter={24}>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                className={'home-signup-left'}>
                <h2>
                  Are you a Sponsor/CRO wanting to get connected with sites of
                  your choice?
                </h2>
                <p>
                  Book a demo to learn more about CRIO Connect and how we can
                  help!
                </p>
                <Button
                  type="primary"
                  className="home-signup-button"
                  onClick={() => {
                    window.open(
                      process.env.REACT_APP_SCHEDULE_A_DEMO_URL,
                      '_blank',
                    );
                  }}>
                  GET ACCESS
                </Button>
              </Col>
              <Col
                xs={0}
                sm={0}
                md={12}
                lg={12}
                xl={12}
                className={'home-signup-right'}>
                <img src={HomeSignup} alt="home-signup" />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
